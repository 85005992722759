import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  AddApprovalFlowTemplateStepMutationVariables,
  ApprovalFlowTemplateStep,
  ApprovalFlowTemplateStepPage,
  ApprovalFlowTemplateStepQueryVariables,
  DeleteApprovalFlowTemplateStepMutationVariables,
  UpdateApprovalFlowTemplateStepMutationVariables,
  UpdateApprovalFlowTemplateStepPositionMutationVariables,
  UpdateApprovalFlowTemplateStepsMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowTemplateStepGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useAddApprovalFlowTemplateStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateStep, AddApprovalFlowTemplateStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateStep, AddApprovalFlowTemplateStepMutationVariables>(
    ApprovalFlowTemplateStepGQL.mutation.addApprovalFlowTemplateStep,
    mutationContext,
  );

export const useUpdateApprovalFlowTemplateStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateStep, UpdateApprovalFlowTemplateStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateStep, UpdateApprovalFlowTemplateStepMutationVariables>(
    ApprovalFlowTemplateStepGQL.mutation.updateApprovalFlowTemplateStep,
    mutationContext,
  );

export const useUpdateApprovalFlowTemplateStepsMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateStep[], UpdateApprovalFlowTemplateStepsMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateStep[], UpdateApprovalFlowTemplateStepsMutationVariables>(
    ApprovalFlowTemplateStepGQL.mutation.updateApprovalFlowTemplateSteps,
    mutationContext,
  );

export const useDeleteApprovalFlowTemplateStepMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateStep, DeleteApprovalFlowTemplateStepMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateStep, DeleteApprovalFlowTemplateStepMutationVariables>(
    ApprovalFlowTemplateStepGQL.mutation.deleteApprovalFlowTemplateStep,
    mutationContext,
  );

export const useUpdateApprovalFlowTemplateStepPositionMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateStep, UpdateApprovalFlowTemplateStepPositionMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateStep, UpdateApprovalFlowTemplateStepPositionMutationVariables>(
    ApprovalFlowTemplateStepGQL.mutation.updateApprovalFlowTemplateStepPosition,
    mutationContext,
  );
export const useApprovalFlowTemplateStepsLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateStepPage, ApprovalFlowTemplateStepQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplateStepPage, ApprovalFlowTemplateStepQueryVariables>(
    ApprovalFlowTemplateStepGQL.query.approvalFlowTemplateSteps,
    queryContext,
  );

export const useApprovalFlowTemplateStepsQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateStepPage, ApprovalFlowTemplateStepQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplateStepPage, ApprovalFlowTemplateStepQueryVariables>(
    ApprovalFlowTemplateStepGQL.query.approvalFlowTemplateSteps,
    queryContext,
  );

export const useApprovalFlowTemplateStepLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateStep, ApprovalFlowTemplateStepQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplateStep, ApprovalFlowTemplateStepQueryVariables>(
    ApprovalFlowTemplateStepGQL.query.approvalFlowTemplateStep,
    queryContext,
  );

export const useApprovalFlowTemplateStepQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateStep, ApprovalFlowTemplateStepQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplateStep, ApprovalFlowTemplateStepQueryVariables>(
    ApprovalFlowTemplateStepGQL.query.approvalFlowTemplateStep,
    queryContext,
  );
