import { graphql } from "../../codegen/gql";

const approvalFlowTemplateUser = graphql(`
  query approvalFlowTemplateUser($id: UUID!) {
    approvalFlowTemplateUser(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      approvalFlowTemplateStep {
        id
      }
      user {
        id
      }
    }
  }
`);

const approvalFlowTemplateUsers = graphql(`
  query approvalFlowTemplateUsers(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowTemplateUsers(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        name
        email
        approvalFlowTemplateStep {
          id
        }
        user {
          id
        }
      }
    }
  }
`);

const addApprovalFlowTemplateUser = graphql(`
  mutation addApprovalFlowTemplateUser($approvalFlowTemplateUser: ApprovalFlowTemplateUserInput!) {
    addApprovalFlowTemplateUser(approvalFlowTemplateUser: $approvalFlowTemplateUser) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      approvalFlowTemplateStep {
        id
      }
    }
  }
`);

const updateApprovalFlowTemplateUser = graphql(`
  mutation updateApprovalFlowTemplateUser($id: UUID!, $approvalFlowTemplateUser: ApprovalFlowTemplateUserInput!) {
    updateApprovalFlowTemplateUser(id: $id, approvalFlowTemplateUser: $approvalFlowTemplateUser) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      approvalFlowTemplateStep {
        id
      }
      user {
        id
      }
    }
  }
`);

const deleteApprovalFlowTemplateUser = graphql(`
  mutation deleteApprovalFlowTemplateUser($id: UUID!) {
    deleteApprovalFlowTemplateUser(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      name
      email
      user {
        id
      }
    }
  }
`);

export const ApprovalFlowTemplateUserGQL = {
  query: { approvalFlowTemplateUser, approvalFlowTemplateUsers },
  mutation: { addApprovalFlowTemplateUser, updateApprovalFlowTemplateUser, deleteApprovalFlowTemplateUser },
};
