import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import {
  AddApprovalFlowTemplateUserMutationVariables,
  ApprovalFlowTemplateUser,
  ApprovalFlowTemplateUserPage,
  ApprovalFlowTemplateUserQueryVariables,
  DeleteApprovalFlowTemplateUserMutationVariables,
  UpdateApprovalFlowTemplateUserMutationVariables,
} from "../../codegen/graphql";
import { ApprovalFlowTemplateUserGQL } from "../../queries";
import { GQLQueryContext } from "../../types";

export const useAddApprovalFlowTemplateUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateUser, AddApprovalFlowTemplateUserMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateUser, AddApprovalFlowTemplateUserMutationVariables>(
    ApprovalFlowTemplateUserGQL.mutation.addApprovalFlowTemplateUser,
    mutationContext,
  );

export const useUpdateApprovalFlowTemplateUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateUser, UpdateApprovalFlowTemplateUserMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateUser, UpdateApprovalFlowTemplateUserMutationVariables>(
    ApprovalFlowTemplateUserGQL.mutation.updateApprovalFlowTemplateUser,
    mutationContext,
  );

export const useDeleteApprovalFlowTemplateUserMutation = (
  mutationContext?: GQLQueryContext<ApprovalFlowTemplateUser, DeleteApprovalFlowTemplateUserMutationVariables>,
) =>
  useGQLMutation<ApprovalFlowTemplateUser, DeleteApprovalFlowTemplateUserMutationVariables>(
    ApprovalFlowTemplateUserGQL.mutation.deleteApprovalFlowTemplateUser,
    mutationContext,
  );

export const useApprovalFlowTemplateUsersLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateUserPage, ApprovalFlowTemplateUserQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplateUserPage, ApprovalFlowTemplateUserQueryVariables>(
    ApprovalFlowTemplateUserGQL.query.approvalFlowTemplateUsers,
    queryContext,
  );

export const useApprovalFlowTemplateUsersQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateUserPage, ApprovalFlowTemplateUserQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplateUserPage, ApprovalFlowTemplateUserQueryVariables>(
    ApprovalFlowTemplateUserGQL.query.approvalFlowTemplateUsers,
    queryContext,
  );

export const useApprovalFlowTemplateUserLazyQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateUser, ApprovalFlowTemplateUserQueryVariables>,
) =>
  useGQLLazyQuery<ApprovalFlowTemplateUser, ApprovalFlowTemplateUserQueryVariables>(
    ApprovalFlowTemplateUserGQL.query.approvalFlowTemplateUser,
    queryContext,
  );

export const useApprovalFlowTemplateUserQuery = (
  queryContext?: GQLQueryContext<ApprovalFlowTemplateUser, ApprovalFlowTemplateUserQueryVariables>,
) =>
  useGQLQuery<ApprovalFlowTemplateUser, ApprovalFlowTemplateUserQueryVariables>(
    ApprovalFlowTemplateUserGQL.query.approvalFlowTemplateUser,
    queryContext,
  );
