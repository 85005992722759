import { graphql } from "../../codegen/gql";

const approvalFlowTemplateStep = graphql(`
  query approvalFlowTemplateStep($id: UUID!) {
    approvalFlowTemplateStep(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

const approvalFlowTemplateSteps = graphql(`
  query approvalFlowTemplateSteps(
    $filterExpression: FilterExpression = null
    $page: Int = null
    $pageSize: Int = null
    $sortField: String = null
    $sortDirection: SortDirection = null
  ) {
    approvalFlowTemplateSteps(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        stepNumber
        requiredAll
        messageHtml
        subjectHtml
        users {
          id
          name
          email
          user {
            id
            mail
          }
        }
      }
    }
  }
`);

const addApprovalFlowTemplateStep = graphql(`
  mutation addApprovalFlowTemplateStep($approvalFlowTemplateStep: ApprovalFlowTemplateStepInput!) {
    addApprovalFlowTemplateStep(approvalFlowTemplateStep: $approvalFlowTemplateStep) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowTemplateStep = graphql(`
  mutation updateApprovalFlowTemplateStep($id: UUID!, $approvalFlowTemplateStep: ApprovalFlowTemplateStepInput!) {
    updateApprovalFlowTemplateStep(id: $id, approvalFlowTemplateStep: $approvalFlowTemplateStep) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowTemplateSteps = graphql(`
  mutation updateApprovalFlowTemplateSteps($steps: [ApprovalFlowTemplateStepUpdateManyInput!]!) {
    updateApprovalFlowTemplateSteps(steps: $steps) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

const updateApprovalFlowTemplateStepPosition = graphql(`
  mutation updateApprovalFlowTemplateStepPosition($approvalFlowTemplateStepId: UUID!, $newPosition: Int!) {
    updateApprovalFlowTemplateStepPosition(
      approvalFlowTemplateStepId: $approvalFlowTemplateStepId
      newPosition: $newPosition
    ) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

const deleteApprovalFlowTemplateStep = graphql(`
  mutation deleteApprovalFlowTemplateStep($id: UUID!) {
    deleteApprovalFlowTemplateStep(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      stepNumber
      requiredAll
      messageHtml
      subjectHtml
      users {
        id
        name
        email
        user {
          id
          mail
        }
      }
    }
  }
`);

export const ApprovalFlowTemplateStepGQL = {
  query: { approvalFlowTemplateStep, approvalFlowTemplateSteps },
  mutation: {
    addApprovalFlowTemplateStep,
    updateApprovalFlowTemplateStep,
    deleteApprovalFlowTemplateStep,
    updateApprovalFlowTemplateSteps,
    updateApprovalFlowTemplateStepPosition,
  },
};
