import { Login, SuccessCaseContentEditorManager, UserLicenseManager } from "@whyuz/app-components";
import { Error404PageNotFound } from "@whyuz/components";
import { WHYUZ_FRONTEND_URL } from "@whyuz/data";
import { useUser } from "@whyuz/services";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../Home").then(({ Home }) => ({ default: Home })));
const AppLayout = lazy(() => import("./components/AppLayout.tsx").then(({ AppLayout }) => ({ default: AppLayout })));
const BenefitRewardsManager = lazy(() =>
  import("../Rewards").then(({ BenefitRewardsManager }) => ({ default: BenefitRewardsManager })),
);
const BenefitRewardManager = lazy(() =>
  import("../Rewards").then(({ BenefitRewardManager }) => ({ default: BenefitRewardManager })),
);

const AssignedRewardsManager = lazy(() =>
  import("../AssignedRewards").then(({ AssignedRewardsManager }) => ({ default: AssignedRewardsManager })),
);
const AssignedRewardManager = lazy(() =>
  import("../AssignedRewards").then(({ AssignedRewardManager }) => ({ default: AssignedRewardManager })),
);

const ContentManager = lazy(() => import("../Contents").then(({ ContentManager }) => ({ default: ContentManager })));
const ContentsManager = lazy(() => import("../Contents").then(({ ContentsManager }) => ({ default: ContentsManager })));
const CustomerManager = lazy(() =>
  import("../Customers").then(({ CustomerManager }) => ({ default: CustomerManager })),
);
const CustomersManager = lazy(() =>
  import("../Customers").then(({ CustomersManager }) => ({ default: CustomersManager })),
);
const ContactManager = lazy(() => import("../Contacts").then(({ ContactManager }) => ({ default: ContactManager })));
const ContactsManager = lazy(() => import("../Contacts").then(({ ContactsManager }) => ({ default: ContactsManager })));
const CorporationsManager = lazy(() =>
  import("../Admin/CorporationAdministration").then(({ CorporationsManager }) => ({
    default: CorporationsManager,
  })),
);
const CorporationManager = lazy(() =>
  import("../Admin/CorporationAdministration").then(({ CorporationManager }) => ({
    default: CorporationManager,
  })),
);
const CorporationAdministratorManager = lazy(() =>
  import("../Admin/CorporationAdministration").then(({ CorporationAdministratorManager }) => ({
    default: CorporationAdministratorManager,
  })),
);
const TenantsManager = lazy(() =>
  import("../Admin/TenantAdministration").then(({ TenantsManager }) => ({ default: TenantsManager })),
);
const TenantManager = lazy(() =>
  import("../Admin/TenantAdministration").then(({ TenantManager }) => ({ default: TenantManager })),
);
const UserLicensesManager = lazy(() =>
  import("../Admin/UserLicense/index.ts").then(({ UserLicensesManager }) => ({
    default: UserLicensesManager,
  })),
);
const MailAccountManager = lazy(() =>
  import("../MailAccounts").then(({ MailAccountManager }) => ({ default: MailAccountManager })),
);
const MailAccountsManager = lazy(() =>
  import("../MailAccounts").then(({ MailAccountsManager }) => ({ default: MailAccountsManager })),
);
const DatabaseManager = lazy(() =>
  import("../Admin/DatabaseAdministration").then(({ DatabaseManager }) => ({ default: DatabaseManager })),
);
const DatabasesManager = lazy(() =>
  import("../Admin/DatabaseAdministration").then(({ DatabasesManager }) => ({ default: DatabasesManager })),
);
const UserTenantRoleManager = lazy(() =>
  import("../Admin/UserTenantRoles").then(({ UserTenantRoleManager }) => ({ default: UserTenantRoleManager })),
);
const UserTenantRolesManager = lazy(() =>
  import("../Admin/UserTenantRoles").then(({ UserTenantRolesManager }) => ({ default: UserTenantRolesManager })),
);

const ApprovalFlowExecutionManager = lazy(() =>
  import("../ApprovalFlow").then(({ ApprovalFlowExecutionManager }) => ({
    default: ApprovalFlowExecutionManager,
  })),
);

const ApprovalFlowExecutionsManager = lazy(() =>
  import("../ApprovalFlow").then(({ ApprovalFlowExecutionsManager }) => ({
    default: ApprovalFlowExecutionsManager,
  })),
);

const ApprovalFlowManager = lazy(() =>
  import("../ApprovalFlow").then(({ ApprovalFlowTemplateManager }) => ({
    default: ApprovalFlowTemplateManager,
  })),
);
const ApprovalFlowsManager = lazy(() =>
  import("../ApprovalFlow").then(({ ApprovalFlowTemplatesManager }) => ({
    default: ApprovalFlowTemplatesManager,
  })),
);

const MailTemplatesManager = lazy(() =>
  import("../MailTemplates").then(({ MailTemplatesManager }) => ({ default: MailTemplatesManager })),
);

const MailTemplateManager = lazy(() =>
  import("../MailTemplates").then(({ MailTemplateManager }) => ({ default: MailTemplateManager })),
);

const SuccessCaseTemplatesManager = lazy(() =>
  import("../SuccessCaseTemplates").then(({ SuccessCaseTemplatesManager }) => ({
    default: SuccessCaseTemplatesManager,
  })),
);

const SuccessCaseTemplateManager = lazy(() =>
  import("../SuccessCaseTemplates").then(({ SuccessCaseTemplateManager }) => ({ default: SuccessCaseTemplateManager })),
);

const SuccessCasesManager = lazy(() =>
  import("../SuccessCase").then(({ SuccessCasesManager }) => ({
    default: SuccessCasesManager,
  })),
);

const SuccessCaseManager = lazy(() =>
  import("../SuccessCase").then(({ SuccessCaseManager }) => ({ default: SuccessCaseManager })),
);

const UserApprovalManager = lazy(() =>
  import("../UserApproval").then(({ UserApprovalManager }) => ({ default: UserApprovalManager })),
);
export const App = () => {
  const userCtx = useUser();

  if (!userCtx.isUserAuthenticated) {
    return <Login />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_FLOW} element={<ApprovalFlowManager />} />
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_FLOW_ID} element={<ApprovalFlowManager />} />
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_FLOWS} element={<ApprovalFlowsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_EXECUTION} element={<ApprovalFlowExecutionManager />} />
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_EXECUTIONS} element={<ApprovalFlowExecutionsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.APPROVAL_EXECUTION_ID} element={<ApprovalFlowExecutionManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTACT} element={<ContactManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTACT_ID} element={<ContactManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTACTS} element={<ContactsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTENT} element={<ContentManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTENT_ID} element={<ContentManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CONTENTS} element={<ContentsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CORPORATION} element={<CorporationManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CORPORATION_ID} element={<CorporationManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CORPORATIONS} element={<CorporationsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CORPORATION_ADMINISTRATOR} element={<CorporationAdministratorManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CUSTOMER_ID} element={<CustomerManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CUSTOMER} element={<CustomerManager />} />
        <Route path={WHYUZ_FRONTEND_URL.CUSTOMERS} element={<CustomersManager />} />
        <Route path={WHYUZ_FRONTEND_URL.DATABASE} element={<DatabaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.DATABASE_ID} element={<DatabaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.DATABASES} element={<DatabasesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.HOME} element={<Home />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_ACCOUNT} element={<MailAccountManager />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_ACCOUNT_ID} element={<MailAccountManager />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_ACCOUNTS} element={<MailAccountsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_TEMPLATE_ID} element={<MailTemplateManager />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_TEMPLATE} element={<MailTemplateManager />} />
        <Route path={WHYUZ_FRONTEND_URL.MAIL_TEMPLATES} element={<MailTemplatesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.TENANT} element={<TenantManager />} />
        <Route path={WHYUZ_FRONTEND_URL.TENANT_ID} element={<TenantManager />} />
        <Route path={WHYUZ_FRONTEND_URL.TENANTS} element={<TenantsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.REWARD_TEMPLATE} element={<BenefitRewardManager />} />
        <Route path={WHYUZ_FRONTEND_URL.REWARD_TEMPLATE_ID} element={<BenefitRewardManager />} />
        <Route path={WHYUZ_FRONTEND_URL.REWARD_TEMPLATES} element={<BenefitRewardsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.ASSIGNED_REWARD} element={<AssignedRewardManager />} />
        <Route path={WHYUZ_FRONTEND_URL.ASSIGNED_REWARD_ID} element={<AssignedRewardManager />} />
        <Route path={WHYUZ_FRONTEND_URL.ASSIGNED_REWARDS} element={<AssignedRewardsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_CONTENT_EDITOR} element={<SuccessCaseContentEditorManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_CONTENT_EDITOR_ID} element={<SuccessCaseContentEditorManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_TEMPLATE} element={<SuccessCaseTemplateManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_TEMPLATE_ID} element={<SuccessCaseTemplateManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_TEMPLATES} element={<SuccessCaseTemplatesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE} element={<SuccessCaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_ID} element={<SuccessCaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASES} element={<SuccessCasesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER} element={<UserTenantRoleManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_ID} element={<UserTenantRoleManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USERS} element={<UserTenantRolesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_LICENSE_ID} element={<UserLicenseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_LICENSE} element={<UserLicenseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_LICENSES} element={<UserLicensesManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_APPROVAL} element={<UserApprovalManager />} />
        <Route path="*" element={<Error404PageNotFound />} />
      </Route>
    </Routes>
  );
};
